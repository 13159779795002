import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MdOutlineFileDownload } from 'react-icons/md'
import { IoMdAdd } from 'react-icons/io'
import * as XLSX from 'xlsx'
import noDataIcon from '../../assets/no-data.webp'
import { CircularProgress } from '@mui/material'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import useToast from '../../hooks/useToast'
import { useNavigate } from 'react-router-dom'
import { fetchSingleManifest } from '../../slices/singleManifestSlice'
import { fetchHubs } from '../../slices/hubsSlice'
import { fetchRiders } from '../../slices/ridersSlice'
import { fetchManifest } from '../../slices/manifestSlice'
import { getDate } from '../../utils'
import ManifestTabs from '../../components/manifests/components/ManifestTabs'
import CreateManifestButtons from '../../components/manifests/components/CreateManifestButtons'
import { Loader } from '../../components/globals'
import Page from '../../containers/Page'
import SearchManifest from '../../components/manifests/components/SearchManifest'
import { fetchExceptions } from '../../slices/exceptionsSlice'
import { emptyBags } from '../../slices/bagsSlice'
import { capitalizeFirstLetter } from '../../utils'
import manifestApi from '../../api/manifest'
import IndividualDropdownOptions from '../../components/manifests/components/IndividualDropDownOption'
import { RaiseExceptionModal } from '../../components/manifests/components/RaiseExceptionModal'
import { ManifestFilterModal } from '../../components/manifests/components/ManifestFilterModal'
import { CreateInternationalCenterModal } from '../../components/manifests/components/CreateInternationalCenterModal'
import ManifestPagination from '../../components/manifests/components/ManifestPagination'

const InternationalManifest = ({ metaTitle }) => {
  const dispatch = useDispatch()
  const allData = useSelector(
    state => state.manifest.dataManifest?.center_manifest
  )
  const allHub = useSelector(state => state?.hubs?.hubs)
  const toast = useToast()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('International')
  const [data, setData] = useState([])
  const [searchVal, setSearchVal] = useState('')
  const [centerModal, setCenterModal] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [raiseModal, setRaiseModal] = useState(false)
  const [destination, setDestination] = useState('')
  const [waybillData, setWaybillData] = useState('')
  const [loading, setLoading] = useState(false)
  const [destId, setDestId] = useState('')
  const [allHubs, setHubs] = useState([])
  const [page_size, setPageSize] = useState(50)
  const [page, setPage] = useState(1)

  const showHubs = () => {
    if (allHub != null) {
      let hubsClone = allHub?.filter(data => data.category !== 'EXPRESS_CENTRE')
      setHubs(hubsClone)
      setCenterModal(true)
    }
  }

  useEffect(() => {
    if ((allData?.length !== 0) & (searchVal === '')) {
      const im = allData?.filter(item => item?.meta?.tpl_service !== 'AAJ')
      setData(im)
    }
  }, [allData, searchVal])

  const handleSearch = value => {
    if (value?.length !== 0) {
      const searchData = data?.filter(item =>
        item?.tracking_id?.includes(value?.toLowerCase())
      )
      setSearchVal(value)
      setData(searchData)
    } else {
      setData(allData)
      setSearchVal('')
    }
  }

  const handleFilter = () => {
    if (destination !== '') {
      const searchData = allData?.filter(
        item => item?.destination_hub_name === destination
      )
      setSearchVal(destination)
      setData(searchData)
    } else {
      setData(allData)
      setSearchVal(destination)
    }
  }

  const createManifest = async tplType => {
    setLoading(true)
    let result = await manifestApi.triggerCreateInternationalManifest({
      destination_hub: destId,
      type: 'IN',
      tpl_service: tplType
    })

    if (result.ok) {
      toast('Manifest created', 'success')
      setLoading(false)
      dispatch(fetchManifest())
    }

    if (!result.ok) {
      toast(result?.problem, 'error')
      setLoading(false)
    }
  }

  const exportManifest = () => {
    // create header
    const header = [
      'S/N',
      'Manifest ID',
      'Total No',
      'Date',
      'Created By',
      'Dispatcher'
    ]

    // create rows
    const rows = allData.map((manifest, id) => {
      let sn = id + 1
      return {
        'S/N': sn,
        'Manifest ID': manifest.tracking_id,
        'Total No': manifest.meta.number_of_shipments,
        Date: getDate(manifest.created_at),
        'Created By': manifest.originating_center,
        Dispatcher: 'Sunday Monday'
      }
    })

    // generate worksheet and workbook
    const sheet = XLSX.utils.json_to_sheet(rows)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, sheet, 'Manifest')

    // fix headers
    XLSX.utils.sheet_add_aoa(sheet, [header], { origin: 'A1' })

    // column width
    const max_width = rows.reduce((w, r) => Math.max(w, r['Sender']?.length))

    sheet['!cols'] = [{ wch: max_width }]

    // create csv file
    XLSX.writeFile(workbook, `manifest.xlsx`, {
      compression: true
    })
  }

  const gotoIndividualManifest = id => {
    navigate(`/manifest/single-international-manifest/${id}`, {
      state: { id }
    })
  }

  const gotoPrint = () => {
    navigate(`/manifest/print`)
  }

  useEffect(() => {
    dispatch(fetchRiders())
    dispatch(fetchHubs())
    dispatch(fetchManifest())
    dispatch(fetchExceptions())
    dispatch(emptyBags())
  }, [dispatch, page_size, page])

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header title={'Manifests'}>
        <SearchManifest
          handleSearch={handleSearch}
          setOpenModal={setOpenModal}
        />
      </Page.Header>
      <Page.Body>
        <div className='w-full pb-20'>
          {/* end of top nav bar */}

          <div className='w-full flex justify-between items-center mt-8'>
            <div className='mt-0'>
              <div className='relative flex flex-row items-end gap-4'>
                <ManifestTabs activeTab={activeTab} />
              </div>
            </div>
            <div className='flex justify-around items-center space-x-8'>
              <button
                className='btn bg-g-400 text-dark-primary'
                onClick={exportManifest}
                disabled={!data?.length}
              >
                <MdOutlineFileDownload
                  size={18}
                  color='#333333'
                  className='mr-1'
                />
                Export
              </button>

              <button
                className='btn btn-primary text-white'
                onClick={() => {
                  showHubs()
                }}
              >
                <IoMdAdd size={18} color='#ffffff' className='mr-1' />
                Create Manifest{' '}
                {loading && <CircularProgress size={13} color='inherit' />}
              </button>

              {/* <Popover>
                <PopoverButton>
                  <button className="btn btn-primary text-white">
                    <IoMdAdd size={18} color="#ffffff" className="mr-1" />
                    Create Manifest
                  </button>
                </PopoverButton>

                <PopoverPanel
                  className={`absolute mt-2 border-none outline-none z-30`}
                >
                  <CreateManifestButtons />
                </PopoverPanel>
              </Popover> */}
            </div>
          </div>

          <div className='flex justify-between items-center mt-8'>
            {searchVal?.length !== 0 && (
              <>
                Showing results for <b>{searchVal}</b>
              </>
            )}
            <div className='flex ml-auto py-4 px-2'>
              <ManifestPagination
                tableId='manifest-table'
                data={data}
                pageSize={page_size}
                totalCount={data?.length}
                page={page}
                setPage={setPage}
                setPageSize={setPageSize}
              />
            </div>
          </div>
          {allData?.length === 0 ? (
            <div className='w-full flex justify-center items-center'>
              <div className='pt-14 w-full flex flex-col items-center justify-center'>
                <img
                  src={noDataIcon}
                  className='w-40 h-40 object-contain'
                  alt='no data icon'
                />
                <p className='text-gray-500 text-left my-2 font-semibold text-base md:text-xl'>
                  No manifest found
                </p>
              </div>
            </div>
          ) : (
            <>
              <div className='w-full overflow-x-auto rounded-lg border border-[#EFEFEF] pb-48'>
                <table className='table--hover'>
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Manifest ID</th>
                      <th>Total No. Of Shipments</th>
                      <th>Date </th>
                      <th>Created By</th>
                      <th>Tpl Service</th>
                      <th>Dispatcher</th>
                      {/* <th>Status</th> */}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((val, id) => (
                      <tr key={id} className='cursor-pointer'>
                        <td
                          onClick={() => {
                            gotoIndividualManifest(val.id)
                            dispatch(fetchSingleManifest(`CM/${val?.id}`))
                          }}
                        >
                          {id + 1}
                        </td>
                        <td
                          onClick={() => {
                            gotoIndividualManifest(val.id)
                            dispatch(fetchSingleManifest(`CM/${val?.id}`))
                          }}
                        >
                          {val?.tracking_id?.toUpperCase()}
                        </td>
                        <td
                          onClick={() => {
                            gotoIndividualManifest(val.id)
                            dispatch(fetchSingleManifest(`CM/${val?.id}`))
                          }}
                        >
                          {val?.meta?.number_of_shipments}
                        </td>
                        <td
                          onClick={() => {
                            gotoIndividualManifest(val.id)
                            dispatch(fetchSingleManifest(`CM/${val?.id}`))
                          }}
                        >
                          {getDate(val?.created_at)}
                        </td>
                        <td
                          onClick={() => {
                            gotoIndividualManifest(val.id)
                            dispatch(fetchSingleManifest(`CM/${val?.id}`))
                          }}
                        >
                          {capitalizeFirstLetter(val?.originating_center)}
                        </td>
                        <td>{val?.meta?.tpl_service}</td>
                        <td
                          onClick={() => {
                            gotoIndividualManifest(val.id)
                            dispatch(fetchSingleManifest(`CM/${val?.id}`))
                          }}
                        >
                          {val?.dispatcher === null
                            ? ''
                            : val?.dispatcher?.name}
                        </td>
                        <td
                          onClick={() => {
                            gotoIndividualManifest(val.id)
                            dispatch(fetchSingleManifest(`CM/${val?.id}`))
                          }}
                        >
                          {!val.scan_events ?? val?.scan_events}
                        </td>

                        {!val?.assigned_to ?? (
                          <td onClick={() => setWaybillData(val)}>
                            <div onClick={() => setWaybillData(val)}>
                              <IndividualDropdownOptions
                                sn={id + 1}
                                id={id}
                                setOpenModal={setRaiseModal}
                                isManifest={true}
                                clickThreeDots={() => {}}
                              />
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                {!allData && (
                  <div className='w-full flex justify-center items-center'>
                    <div className='pt-14 w-full flex flex-col items-center justify-center'>
                      <Loader />
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <RaiseExceptionModal
          openModal={raiseModal}
          setOpenModal={setRaiseModal}
          manifestData={waybillData?.tracking_id}
          type='MANIFEST'
          location={waybillData?.destination_hub_name}
          hub={waybillData?.destination_hub}
        />
        <ManifestFilterModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          setDestination={setDestination}
          destination={destination}
          handleFilter={handleFilter}
          setSearchVal={setSearchVal}
        />
        <CreateInternationalCenterModal
          setCenterModal={setCenterModal}
          centerModal={centerModal}
          createManifest={createManifest}
          setDestId={setDestId}
          allHubs={allHubs}
        />
      </Page.Body>
    </Page>
  )
}

export default InternationalManifest
