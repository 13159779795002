import { routeGroups } from './routes'

const ROLES = {
  1: {
    id: 1,
    name: 'COO',
    domain: { name: 'all-branches', index: 3 },
    permissions: {
      shipments: {
        update_status: true
      },
      manifest: {
        center: true,
        transfer: true,
        doorstep: true,
        intl: true
      },
      orders: {
        create: false
      }
    },
    routes: [
      routeGroups.DASHBOARD,
      routeGroups.QUOTES,
      routeGroups.ORDERS,
      routeGroups.SHIPMENTS,
      routeGroups.INVOICES,
      routeGroups.RECEIPTS,
      routeGroups.TRACK,
      routeGroups.MANIFEST,
      routeGroups.INVENTORY,
      routeGroups.FLEET,
      routeGroups.USERS,
      routeGroups.REPORTS,
      routeGroups.ACCOUNT_SETTINGS,
      routeGroups.SALESFORCE
    ]
  },
  2: {
    id: 2,
    name: 'Area Manager',
    domain: { name: 'area', index: 1 },
    permissions: {
      shipments: {
        update_status: true
      },
      manifest: {
        center: true,
        transfer: true,
        doorstep: true,
        intl: true
      },
      orders: {
        create: false
      }
    },
    routes: [
      routeGroups.DASHBOARD,
      routeGroups.QUOTES,
      routeGroups.ORDERS,
      routeGroups.INVOICES,
      routeGroups.RECEIPTS,
      routeGroups.SHIPMENTS,
      routeGroups.TRANSACTIONS,
      routeGroups.MANIFEST,
      routeGroups.INVENTORY,
      routeGroups.REPORTS,
      routeGroups.ACCOUNT_SETTINGS,
      routeGroups.USERS,
      routeGroups.TRACK,
    ]
  },
  3: {
    id: 3,
    name: 'Regional Manager',
    domain: { name: 'region', index: 2 },
    permissions: {
      shipments: {
        update_status: true
      },
      manifest: {
        center: true,
        transfer: true,
        doorstep: true,
        intl: true
      },
      orders: {
        create: false
      }
    },
    routes: [
      routeGroups.DASHBOARD,
      routeGroups.QUOTES,
      routeGroups.ORDERS,
      routeGroups.SHIPMENTS,
      routeGroups.INVOICES,
      routeGroups.RECEIPTS,
      routeGroups.TRANSACTIONS,
      routeGroups.TRACK,
      routeGroups.MANIFEST,
      routeGroups.TRACK,
      routeGroups.INVENTORY,
      routeGroups.REPORTS,
      routeGroups.ACCOUNT_SETTINGS,
      routeGroups.USERS,
    ]
  },
  4: {
    id: 4,
    name: 'Branch Supervisor',
    domain: { name: 'branch', index: 0 },
    permissions: {
      transactions: {
        remit: true
      },
      manifest: {
        center: true,
        transfer: false,
        doorstep: false,
        intl: false
      },
      shipments: {
        update_status: true
      },
      orders: {
        create: true,
        process: true
      }
    },
    routes: [
      routeGroups.DASHBOARD,
      routeGroups.QUOTES,
      routeGroups.ORDERS,
      routeGroups.SHIPMENTS,
      routeGroups.MANIFEST,
      routeGroups.INVOICES,
      routeGroups.RECEIPTS,
      routeGroups.TRANSACTIONS,
      routeGroups.TRACK,
      routeGroups.ACCOUNT_SETTINGS,
      routeGroups.INVENTORY,
      routeGroups.SALESFORCE
    ]
  },
  5: {
    id: 5,
    name: 'Human Resource Manager',
    domain: { name: 'branch', index: 0 },
    permissions: {},
    routes: [
      routeGroups.DASHBOARD,
      routeGroups.ACCOUNT_SETTINGS,
      routeGroups.USERS,
    ]
  },
  6: {
    id: 6,
    name: 'Customer Support Personnel',
    domain: { name: 'all-branches', index: 3 },
    permissions: {
      orders: {
        create: true,
        process: true
      }
    },
    routes: [
      routeGroups.DASHBOARD,
      routeGroups.QUOTES,
      routeGroups.ORDERS,
      routeGroups.SHIPMENTS,
      routeGroups.INVOICES,
      routeGroups.RECEIPTS,
      routeGroups.TRANSACTIONS,
      routeGroups.TRACK,
      routeGroups.ACCOUNT_SETTINGS,
      routeGroups.REPORTS,
      routeGroups.USERS,
      routeGroups.SALESFORCE
    ]
  },
  7: {
    id: 6,
    name: 'Sales/Account Manager',
    domain: { name: 'branch', index: 0 },
    permissions: {
      orders: {
        create: true,
        process: true
      }
    },
    routes: [
      routeGroups.DASHBOARD,
      routeGroups.QUOTES,
      routeGroups.ORDERS,
      routeGroups.SHIPMENTS,
      routeGroups.INVOICES,
      routeGroups.RECEIPTS,
      routeGroups.TRANSACTIONS,
      routeGroups.TRACK,
      routeGroups.ACCOUNT_SETTINGS,
      routeGroups.REPORTS,
      routeGroups.USERS,
    ]
  },
  8: {
    id: 8,
    name: 'Booking Agent',
    domain: { name: 'branch', index: 0 },
    permissions: {
      orders: {
        create: true,
        process: true
      }
    },
    routes: [
      routeGroups.DASHBOARD,
      routeGroups.QUOTES,
      routeGroups.ORDERS,
      routeGroups.SHIPMENTS,
      routeGroups.INVOICES,
      routeGroups.MANIFEST,
      routeGroups.RECEIPTS,
      routeGroups.TRANSACTIONS,
      routeGroups.TRACK,
      routeGroups.ACCOUNT_SETTINGS,
    ]
  },
  9: {
    id: 9,
    name: 'Booking Manager',
    domain: { name: 'branch', index: 0 },
    permissions: {
      transactions: {
        remit: true
      },
      manifest: {
        center: true,
        transfer: false,
        doorstep: false,
        intl: false
      },
      orders: {
        create: true,
        process: true
      }
    },
    routes: [
      routeGroups.DASHBOARD,
      routeGroups.QUOTES,
      routeGroups.ORDERS,
      routeGroups.SHIPMENTS,
      routeGroups.MANIFEST,
      routeGroups.INVOICES,
      routeGroups.RECEIPTS,
      routeGroups.TRANSACTIONS,
      routeGroups.TRACK,
      routeGroups.ACCOUNT_SETTINGS,
      routeGroups.USERS,
    ]
  },
  10: {
    id: 10,
    name: 'Astro',
    domain: { name: 'all-branches', index: 3 },
    permissions: {
      manifest: {
        center: true,
        transfer: true,
        doorstep: true,
        intl: true
      }
    },
    routes: [
      routeGroups.DASHBOARD,
      routeGroups.MANIFEST,
      routeGroups.ACCOUNT_SETTINGS,
    ]
  },
  11: {
    id: 11,
    name: 'Fleet Manager',
    domain: { name: 'all-branches', index: 3 },
    permissions: {},
    routes: [
      routeGroups.DASHBOARD,
      routeGroups.TRACK,
      routeGroups.FLEET,
      routeGroups.ACCOUNT_SETTINGS,
      routeGroups.REPORTS,
      routeGroups.USERS,
    ]
  },
  12: {
    id: 12,
    name: 'CFO',
    domain: { name: 'all-branches', index: 3 },
    permissions: {
      transactions: {
        remit: true
      },
      shipments: {
        update_status: true
      },
      orders: {
        create: false
      }
    },
    routes: [
      routeGroups.DASHBOARD,
      routeGroups.QUOTES,
      routeGroups.ORDERS,
      routeGroups.SHIPMENTS,
      routeGroups.INVOICES,
      routeGroups.RECEIPTS,
      routeGroups.TRANSACTIONS,
      routeGroups.TRACK,
      routeGroups.ACCOUNT_SETTINGS,
    ]
  },
  13: {
    id: 13,
    name: 'Accountant',
    domain: { name: 'all-branches', index: 3 },
    permissions: {
      orders: {
        create: false
      }
    },
    routes: [
      routeGroups.DASHBOARD,
      routeGroups.QUOTES,
      routeGroups.ORDERS,
      routeGroups.SHIPMENTS,
      routeGroups.INVOICES,
      routeGroups.RECEIPTS,
      routeGroups.TRANSACTIONS,
      routeGroups.TRACK,
      routeGroups.ACCOUNT_SETTINGS,
    ]
  },
  14: {
    id: 14,
    name: 'Operations and Inventory Officer',
    domain: { name: 'branch', index: 0 },
    permissions: {
      manifest: {
        center: true,
        transfer: true,
        doorstep: true,
        intl: true
      }
    },
    routes: [
      routeGroups.DASHBOARD,
      routeGroups.MANIFEST,
      routeGroups.ACCOUNT_SETTINGS,
      routeGroups.REPORTS,
      routeGroups.INVENTORY,
    ]
  },
  15: {
    id: 15,
    name: 'MD/CEO',
    domain: { name: 'all-branches', index: 3 },
    permissions: {
      transactions: {
        remit: true
      },
      manifest: {
        center: true,
        transfer: true,
        doorstep: true,
        intl: true
      },
      shipments: {
        update_status: true
      },

      orders: {
        create: true,
        process: true
      }
    },
    routes: [
      routeGroups.DASHBOARD,
      routeGroups.QUOTES,
      routeGroups.ORDERS,
      routeGroups.SHIPMENTS,
      routeGroups.MANIFEST,
      routeGroups.INVOICES,
      routeGroups.RECEIPTS,
      routeGroups.TRANSACTIONS,
      routeGroups.TRACK,
      routeGroups.FLEET,
      routeGroups.ACCOUNT_SETTINGS,
      routeGroups.REPORTS,
      routeGroups.INVENTORY,
      routeGroups.USERS,
      routeGroups.SALESFORCE
    ]
  },
  16: {
    id: 16,
    name: 'CSO',
    domain: { name: 'all-branches', index: 3 },
    permissions: {},
    routes: [
      routeGroups.DASHBOARD,
      routeGroups.FLEET,
      routeGroups.ACCOUNT_SETTINGS,
      routeGroups.REPORTS,
      routeGroups.INVENTORY,
      routeGroups.USERS,
    ]
  },
  17: {
    id: 17,
    name: 'Software Engineer',
    domain: { name: 'all-branches', index: 3 },
    permissions: {
      transactions: {
        remit: true
      },
      manifest: {
        center: true,
        transfer: true,
        doorstep: true,
        intl: true
      },
      shipments: {
        update_status: true
      },
      orders: {
        create: true,
        process: true
      }
    },
    routes: [
      routeGroups.DASHBOARD,
      routeGroups.QUOTES,
      routeGroups.ORDERS,
      routeGroups.SHIPMENTS,
      routeGroups.MANIFEST,
      routeGroups.INVOICES,
      routeGroups.RECEIPTS,
      routeGroups.TRANSACTIONS,
      routeGroups.TRACK,
      routeGroups.FLEET,
      routeGroups.ACCOUNT_SETTINGS,
      routeGroups.REPORTS,
      routeGroups.INVENTORY,
      routeGroups.USERS,
      routeGroups.SALESFORCE
    ]
  },
  18: {
    id: 18,
    name: 'Marketing Manager',
    domain: { name: 'all-branches', index: 3 },
    permissions: {
      orders: {
        create: false
      }
    },
    routes: [
      routeGroups.DASHBOARD,
      routeGroups.QUOTES,
      routeGroups.ORDERS,
      routeGroups.SHIPMENTS,
      routeGroups.INVOICES,
      routeGroups.RECEIPTS,
      routeGroups.TRANSACTIONS,
      routeGroups.ACCOUNT_SETTINGS,
      routeGroups.REPORTS,
      routeGroups.SALESFORCE
    ]
  },
  19: {
    id: 19,
    name: 'Transit/Gateway Operator',
    domain: 'all-branch',
    permissions: {
      shipments: {
        update_status: true
      },
      manifest: {
        center: true,
        transfer: true,
        doorstep: true,
        intl: true
      },
      orders: {
        create: true,
        process: true
      }
    },
    routes: [
      routeGroups.DASHBOARD,
      routeGroups.QUOTES,
      routeGroups.ORDERS,
      routeGroups.SHIPMENTS,
      routeGroups.MANIFEST,
      routeGroups.TRACK,
      routeGroups.ACCOUNT_SETTINGS,
    ]
  }
}

export default ROLES
